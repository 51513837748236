import * as React from 'react';

interface Props {
  pageName: string // cookieの干渉を避けるためにページ名を入れる
  className: string
  errorMessageClass: string
  inputName: string
  inputElements: any
  labelElements: any
  checked?: boolean
  errorCheck: any
  errorDisable?: boolean
  label?: string
  require?: boolean
  registrationAgreeLabel?: boolean
}

export const Checkbox = (props: Props) => {
  const initValue = props.checked || false
  const [value, setValue] = React.useState(initValue)
  const is_changed_value = React.useRef(false)

  const handleChange = (event: any) => {
    setValue(event.target.checked)
    document.cookie = `${props.pageName ? `${props.pageName}-` : ''}${props.inputName}=${event.target.checked}; max-age=172800; path=/`
    props.errorCheck()

    is_changed_value.current = true
  }

  React.useEffect(() => {
    if(props.labelElements && !props.registrationAgreeLabel) {
      props.inputElements.appendChild(props.labelElements)
    }

    document.cookie = `${props.pageName ? `${props.pageName}-` : ''}${props.inputName}=${initValue}; max-age=172800; path=/`
  }, []);

  const isError = value === false && is_changed_value.current && !props.errorDisable
  const labelError = value === false && !props.errorDisable // 初回の押下のため条件をエラーメッセージ表示と分けている

  return (
    <>
      <input
        type="checkbox"
        className={`${props.className} ${value === false || props.errorDisable ? '' : 'checked'} ga-${props.inputName}`}
        name={props.inputName}
        id={props.inputName}
        onChange={e => handleChange(e)}
        value={""}
        checked={value}
      />
      { props.registrationAgreeLabel ?
        <label id="be_form_agree_check_label" htmlFor="agree" className="c-formItem__label -hasChedkbox">
          <span>
            <a href="/user_policy" target="_blank">利用規約</a>
            ・
            <a href="https://b-engineer.co.jp/privacy-for-independence/" target="_blank">個人情報の取扱い</a>
            に同意します。
            <span className={`c-formItem__required ${labelError ? '' : 'label-valid'}`}>必須{labelError ? '' : ' ✔︎'}</span>
          </span>
        </label> : <></>
      }

      {isError ? <p className={`${props.errorMessageClass}`}>必須項目です</p> : <></>}
    </>
  );
}
