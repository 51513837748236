import * as React from 'react';
import { checkValidation } from 'tsRoot/validator/validatorBase'
import { getValuesFromCookieToInputs } from 'tsRoot/actions/cookie'

interface Props {
  pageName: string // cookieの干渉を避けるためにページ名を入れる
  className: string
  errorMessageClass: string
  errorFlug: string
  inputName: string
  errorCheckEnable?: boolean
  type?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week'
  placeholder?: string,
  error?: boolean
  validationRule: any
  errorCheck: any
  popItemOption?: 'left'
  popItem?: React.ReactNode
  setState?: React.Dispatch<React.SetStateAction<any>>
  label?: string
  require?: boolean
}

export const Input = (props: Props) => {
  const initValue = getValuesFromCookieToInputs(props.inputName, props.pageName)

  const [value, setValue] = React.useState(initValue)
  const [blurCount, setBlurCount] = React.useState(0) // Blurのカウントをstateで行うことで強制的に再レンダーさせる

  const is_changed_value = React.useRef(initValue ? true : false) // 値が画面描画後から一度でも変更されたことがあるかのフラグ
  const already_blur = React.useRef(initValue ? true : false)     // フォームが一度選択された後にフォーカスが外れたことがあるかのフラグ

  const validateErrorMessageArray = props.errorCheckEnable || is_changed_value.current ? checkValidation(value, props.validationRule) : []

  const is_error = props.errorCheckEnable || (already_blur.current && (props.error || validateErrorMessageArray.length > 0))
  const validateErrorMessage = () => {
    return (
      validateErrorMessageArray.map((message: string, idx: number) => {
        if(idx == 0) return <span className={`${props.errorMessageClass}`} key={`errorMessage${idx}`}>{message}</span>

        return <span className={`${props.errorMessageClass}`} key={`errorMessage${idx}`}><br />{message}</span>
      })
    )
  }

  const handleChange = (event: any) => {
    setValue(event.target.value)
    document.cookie = `${props.pageName ? `${props.pageName}-` : ''}${props.inputName}=${encodeURIComponent(event.target.value)}; max-age=172800; path=/`
    props.errorCheck()

    if(props.setState) {
      props.setState(event.target.value)
    }
    is_changed_value.current = true
  };

  const handleBlur = () => {
    setBlurCount(blurCount + 1)
    already_blur.current = true
  };

  const isComplete = !is_error && already_blur.current && is_changed_value.current

  return (
    <>
      { props.label ?
        <label htmlFor="" className={`c-formItem__label`}>
          <span>{props.label}</span>
          {props.require ? <span className={`c-formItem__required ${isComplete ? 'label-valid' : ''}`}>必須{isComplete ? ' ✔︎' : ''}</span> : <></> }
        </label> : <></>
      }
      <input
        id={props.inputName}
        className={`${props.className} ${is_error ? props.errorFlug : ''} ga-${props.inputName}`}
        placeholder={props.placeholder}
        type={props.type}
        onChange={e => handleChange(e)}
        onBlur={() => handleBlur()}
        /* @ts-ignore */
        value={value}
      />
      {props.popItem ?
        <div className={`c-contact__mess__box --top ${props.popItemOption ? `--${props.popItemOption}` : ''} pop_${props.inputName}`}>
          <p>{props.popItem}</p>
          <span></span>
        </div> : <></>
      }
      {is_error ? validateErrorMessage() : <></>}
    </>
  );
}
